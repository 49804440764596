import { defineStore } from "pinia";
import type { VehicleHistory } from "~/types/vehicles";

// Config
const siteName = "GRERARD AUTOMOBILE";
const siteRegion = "GIMONT (32)";
const sitePhone = "05 62 67 88 20";

export const useAppStore = defineStore("app", {
	state: () =>  ({

		//oldconfig
		tokenSite: null,
		mobile_width: 1100,
		showMenu: false,
		showQuote: false,
		currentEtablissement: {
			siteId: 1,
			templateId: 0,
			vehicules: <VehicleHistory[]>[],
			InfoVehicule: [
				{
					idVehicule: 1,
					nomVehicule: 'Jaguar F-Type décapotable (x152)',
					descVehicule: 'SCV6 400 SPORT AWD 400cv <br> Essence Décapotable Traction intégrale',
					immat: 'AA-123-BB',
					Vin: 'XXXXX000000000000',
					imgMarque: '/img/pages/catalogue/marque.png',
				},
			],
			CatalogueCourant: [
				{
					idCourant: 1,
					nomCat: 'Freinage',
					nomSubCat: [
						{
							idnomSubCat: 2,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,
						},
						{
							idnomSubCat: 3,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,
						},
						{
							idnomSubCat: 4,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,

						},
					],
					imgCat: '/img/pages/catalogue/courant/freinage.png',
				},
				{
					idCourant: 5,
					nomCat: 'Filtration',
					nomSubCat: [
						{
							idnomSubCat: 6,
							nomSubCat: "Nom du produit",
							linkSubCat: "/catalogue/liste-produits",
							icon: false,

						},
						{
							idnomSubCat: 7,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,

						},
						{
							idnomSubCat: 8,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,

						},
					],
					imgCat: '/img/pages/catalogue/courant/filtration.png',
				},
				{
					idCourant: 9,
					nomCat: 'Courroies et distribution',
					nomSubCat: [
						{
							idnomSubCat: 10,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,

						},
						{
							idnomSubCat: 11,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,

						},
						{
							idnomSubCat: 12,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,

						},
					],
					imgCat: '/img/pages/catalogue/courant/distribution.png',
				},

			],
			CataloguePiece: [],
			FiltreMarquesVehicules: [
				{
					idMarque: 1,
					nomMarque: 'Peugeot',
				},
				{
					idMarque: 2,
					nomMarque: 'Citroën',
				},
				{
					idMarque: 3,
					nomMarque: 'Renault',
				},
				{
					idMarque: 4,
					nomMarque: 'Opel',
				},
				{
					idMarque: 5,
					nomMarque: 'Volkswagen',
				},
				{
					idMarque: 6,
					nomMarque: 'Mercedes',
				},
				{
					idMarque: 7,
					nomMarque: 'Mercedes',
				},
				{
					idMarque: 8,
					nomMarque: 'Audi',
				},
				{
					idMarque: 9,
					nomMarque: 'BMW',
				},
			],			
			siteName: "Mecanobox",
			logo: "",
			socials: {
				facebook: "",
				twitter: "",
				instagram: "",
				tiktok: "",
				youtube: "",
				daylimotion: "",
				twitch: "",
				whatsapp: "",
			},
			contactInformation: {
				address: "RUE DE LA TUILERIE",
				city: "GIMONT",
				zipcode: "32200",
				country: "FRANCE",
				primaryPhone: "05 62 67 88 20",
				phones: [],
				primaryEmail: "contact@gerardauto.fr",
				emails: [],
				gps: {
					latitude: "",
					longitude: "",
					altitude: "",
				},
			},
			seo: {
				describe: "Mecanobox descriptionss",
				sharedImage: "",
				title: "AutopartsBox",
				keywords: "gara, mécanicien, vente en ligne",
				favicon: "",
				googleVerification: "",
			},
			htSite: true,
			actualVisitCatalogue: "",
			isCheckStockBeforeOrder: true,
			deliveryAddressModifyUrl: "",
			showOnlyMerchantBrands: false,
			sendOrdersToErpOn: true,
			isOrdersDataSourceFromErp: true,
			isImportMerchantExternalOrders: false,
			isConsigneIncluded: true,
			stateMode: 2,
			erpCodeClientWeb: ""
		},
		currentTemplate: {
			id: 0,
			name: "Default Template",
			template: {
				header: {
					name: "DefaultHeader",
				},
				navbar: {
					name: "DefaultNavBar",
				},
				home: [
					// {
					// 	name: "SearcheVehiculeDefault",
					// 	type_name: "SearchVehicule",
					// },
					// {
					// 	name: "DefaultThematic",
					// 	type_name: "Thematic",
					// },
					// {
					// 	name: "DefaultSlideModule",
					// 	type_name: "Slide",
					// },
					// {
					// 	name: "DefaultSiteMapContactModule",
					// 	type_name: "SiteMapContact",
					// },
				],
				contact: [
					{
						name: "DefaultContact",
						type_name: "Contact",
					},
				],
				footer: {
					name: "DefaultFooter",
				},
				fixedButton: {
					name: "FixedBarButton",
				},
			},
		},
		currentDashbord: {
			id: 0,
			name: "Default Template",
			template: {
				header: {
					name: "DefaultHeader",
				},
				navbar: {
					name: "DefaultAppNavbar",
				},
				home: [
					// {
					// 	name: "SearcheVehiculeDefault",
					// 	type_name: "SearchVehicule",
					// },
					// {
					// 	name: "DefaultThematic",
					// 	type_name: "Thematic",
					// },
					// {
					// 	name: "DefaultSlideModule",
					// 	type_name: "Slide",
					// },
					// {
					// 	name: "DefaultSiteMapContactModule",
					// 	type_name: "SiteMapContact",
					// },
				],
				contact: [
					{
						name: "DefaultContact",
						type_name: "Contact",
					},
				],
				footer: {
					name: "DefaultFooter",
				},
				fixedButton: {
					name: "FixedBarButton",
				},
			},
		},
		infoCar: {
			lastKType: "",
			lastImmat: "",
			actualKType: "",
			actualImmat: "",
			carGenericDatas: [],
			carPlateDatas: [],
			lastVin: "",
			actualVin: ""
		},
		shopMenu: [
			{
				menuItem : "demarrage-batterie",
				item : [
					{
						link: "ampoule-eclairage",
						text: "Ampoules d'éclairage"
					},
					{
						link: "batterie-auto",
						text: "Batterie auto"
					},
					{
						link: "chargeur-booster-batteries",
						text: "Chargeur, booster batteries"
					},
					{
						link: "cable-demarrage",
						text: "Câble de démarrage"
					},
					{
						link: "batterie-camping-car",
						text: "Batterie camping car, poids lourd, agricole"
					},
					{
						link: "batteries-moto",
						text: "Batteries moto"
					},
					{
						link: "commutateurs-relais",
						text: "Commutateurs relais"
					},
					{
						link: "cosses-boitiers",
						text: "Cosses et boîtiers"
					},
					{
						link: "cable-electrique",
						text: "Câble électrique"
					},
					{
						link: "demarrage-batterie",
						text: "Démarrage, batterie"
					}
				]
			},
			{
				menuItem: "atelier-hygiene",
				item: [
					{
						link: "hygiene-protection-sanitaire",
						text: "Hygiène et protection sanitaire"
					},
					{
						link: "savon-hygiene-mains",
						text: "Savon, hygiène des mains"
					},
					{
						link: "sacs-poubelle-supports",
						text: "Sacs poubelle et supports"
					},
					{
						link: "hygiene-alimentaire",
						text: "Hygiène alimentaire"
					},
					{
						link: "nettoyant-vitre",
						text: "Nettoyant vitre"
					}
				]
			},
			{
				menuItem: "atelier-consommable",
				item: [
					{
						link: "cartouche-pot-de-graisse",
						text: "Cartouche, pot de graisse"
					},
					{
						link: "nettoyant-freins-degrippant",
						text: "Nettoyant freins, dégrippant"
					},
					{
						link: "consommables-atelier",
						text: "Consommables Atelier"
					},
					{
						link: "absorbant-huile-eau-hydrocarbure",
						text: "Absorbant huile, eau, hydrocarbure"
					},
					{
						link: "frein-filet",
						text: "Frein filet"
					},
					{
						link: "atelier-consommables",
						text: "Atelier - Consommables"
					}
				]
			},
			{
				menuItem: "huile-liquides",
				item: [
					{
						link: "huile-moteur",
						text: "Huile moteur"
					},
					{
						link: "liquide-de-refroidissement",
						text: "Liquide de refroidissement"
					},
					{
						link: "liquide-frein",
						text: "Liquide frein (Dot 4 - 5-1 - LHM)"
					},
					{
						link: "huile-boite-de-vitesses",
						text: "Huile pour boîte de vitesses"
					},
					{
						link: "huile-liquides",
						text: "Huile, liquides"
					}
				]
			},
			{
				menuItem: "peinture",
				item: [
					{
						link: "mastic-carrosserie",
						text: "Mastic carrosserie"
					},
					{
						link: "esthetique-correction-peinture",
						text: "Esthétique, correction peinture"
					},
					{
						link: "pistolet-a-peinture",
						text: "Pistolet à peinture"
					},
					{
						link: "peinture",
						text: "Peinture"
					},
					{
						link: "mastic-bandage-echappement",
						text: "Mastic et bandage d'échappement"
					}
				]
			},
			{
				menuItem: "additif-moteur",
				item: [
					{
						link: "a-remplir",
						text: "A remplir"
					}
				]
			}
		],
		auth: {
			token: "",
			dateStart: "",
			dateEnd: "",
			userToken: "",
			dateEndUserToken: ""
		},
		// userConnected: {
		// 	userId: 10, // 1
		// 	userEmail: "demo@autopartspro.fr" // s.bravi@graphibox.net
		// }
	}),
	actions: {
		saveActualVisitCatalog(productName: string) {
			this.currentEtablissement.actualVisitCatalogue = productName;
		}
	}
});